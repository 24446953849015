import type {ReactElement} from 'react'
import {AuthShell} from 'core/components'
import useLiveChat from 'hooks/use-live-chat'
import {Seo, AuthForm} from 'components'

const LoginPage = () => {
  const liveChat = useLiveChat()
  return (
    <AuthShell onHelp={() => liveChat.open()}>
      <Seo title="Login" />
      <AuthForm />
    </AuthShell>
  )
}

LoginPage.getLayout = (page: ReactElement) => page

export default LoginPage
